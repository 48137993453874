import {
    AgentResponse, AgentResponseData,
    DataFrom, ExtendedRequestParams,
    ListResponseData, NullAgentResponse,
} from './misc';
import { requestCommandIE, requestMethod } from './emails';

export function cutOrderByParam(params) {
    let newParams = JSON.parse(JSON.stringify(params));
    if (newParams && newParams['order_by']) {
        newParams['order_by'] = newParams['order_by'][0];
    }
    return newParams;
}

export enum AccountStatus {
    active = 'active',
    inactive = 'inactive',
}

interface ServerSpace {
    total_space: number;
    account_space: number;
}
interface ServerDefaultsLimits {
    limit_mode: number;
    account: number;
    domain: number;
    sender_email: number;
    script: number;
}

export interface ResponseEmailSettingsItem {
    name: string;
    limit_bytes: number;
    used_bytes: number;
    state: AccountStatus;
    description: string;
}


export class AccountItem {
    public name: string;
    public limitBytes: number;
    public state: AccountStatus;
    public description: string;
    public usedBytes: number;

    constructor(item: ResponseEmailSettingsItem) {
        this.name = item.name;
        this.limitBytes = item.limit_bytes;
        this.description = item.description;
        this.usedBytes = item.used_bytes;
        this.state = item.state;
    }
}


export class EmailsSettingsApis {

    static list = (params) =>  <ExtendedRequestParams<DataFrom<AccountListResponseData>>> {
        command: requestCommandIE,
        method: ['accounts'],
        params: {
            ...cutOrderByParam(params),
            request_method: requestMethod.get,
        },
    }

    static updateAccount = ({limit_bytes, userName, account_name}: {
        limit_bytes?: number,
        userName: string,
        account_name?: string,
    }) => <ExtendedRequestParams<DataFrom<AccountResponseData>>> {
        command: requestCommandIE,
        method: ['accounts', userName, 'settings'],
        params: {
            request_method: requestMethod.put,
            ...(account_name ? {account_name} : {}),
            ...(limit_bytes ? {limit_bytes} : {}),
        },
    }

    static getAccountSettings = () => <ExtendedRequestParams<DataFrom<ServerSpaceResponse>>> {
        command: requestCommandIE,
        method: ['accounts', '{account_name}', 'settings'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static getLimits = () => <ExtendedRequestParams<DataFrom<ServerLimitResponse>>> {
        command: requestCommandIE,
        method: ['so', 'limits', 'defaults'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static updateLimits = (data) =>
        <ExtendedRequestParams<DataFrom<ServerLimitResponse>>> {
        command: requestCommandIE,
        method: ['so', 'limits', 'defaults'],
        params: {
            ...data,
            request_method: requestMethod.post,
        },
    }

    static getAccountList = () =>
        <ExtendedRequestParams<DataFrom<AgentResponse<ListResponseData<string>>>>> {
        command: requestCommandIE,
        method: ['unix-users'],
        params: {
            request_method: requestMethod.get,
        },
    }

    static deleteAccounts = ({userName}: {
        userName: string,
    }) => <ExtendedRequestParams<DataFrom<NullAgentResponse>>> {
        command: requestCommandIE,
        method: ['accounts', userName, 'settings'],
        params: {
            request_method: requestMethod.delete,
        },
    }
}

export type AccountListResponseData = AgentResponse<ListResponseData<ResponseEmailSettingsItem>>;

export type ServerSpaceResponse = AgentResponse<AgentResponseData<ServerSpace>>;

export type ServerLimitResponse = AgentResponse<AgentResponseData<ServerDefaultsLimits>>;

export type AccountResponseData = AgentResponse<AgentResponseData<ResponseEmailSettingsItem>>;
